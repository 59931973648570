<template>
  <ThankYouPage />
</template>

<script>
import AbstractPage from 'chimera/all/components/page/AbstractPage'
import ThankYouPage from 'chimera/all/themes/blueflow/page/ThankYou/ThankYouPage'

export default {
  components: {
    ThankYouPage,
  },

  extends: AbstractPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Bewerbung abgeschlossen',
      headDescription:
        'Geben Sie Ihre Postleitzahl ein und vergleichen Sie kostenlos 6 Angebote von Top-Unternehmen in Ihrer Region. ✓Der beste Preis für Ihren Solarmodullieferanten ✓Sparen Sie bis zu 40% ✓100% kostenlos!',
      path: '/angebotsanfragen/bewerbung-abgeschlossen',
    }
  },
}
</script>
